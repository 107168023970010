
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { mdiSoccer } from '@mdi/js';
import { VuetifyMixin, BAIconsMixin } from '../../mixins';
import { SportName } from '@/../types/enums';

@Component
export default class SportCard extends Mixins(VuetifyMixin, BAIconsMixin){
	@Prop({ default: SportName.Soccer }) private sport: SportName;

	get Icon(): string{
		return mdiSoccer;
	}
	get Text(): string{
		return `${this.sport} profile`;
	}
}
