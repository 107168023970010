var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',[_c('SubscriptionPlanInfoProvider',_vm._b({staticClass:"subscriptions-table-wrapper d-flex",attrs:{"country":_vm.CustomerCountry},scopedSlots:_vm._u([{key:"default",fn:function({
					state,
					plans,
					PlanPricesFormatted,
					MonthlyPlanPricesFormatted,
					SlashPricesFormatted,
					AllFeatures,
				}){return [_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-text',[_c('PromotionsCard')],1),(state.IsError)?_c('v-card-text',[_c('FailureStateCard',{attrs:{"state":state}})],1):_vm._e(),(state.IsReady)?_c('v-card-text',[(_vm.UseCardView)?_vm._l((plans),function(plan,index){return _c('div',{key:plan,staticClass:"d-flex"},[_c('SubscriptionsTablePlan',{staticClass:"mb-6",attrs:{"plans":[plan],"PlanPricesFormatted":[PlanPricesFormatted[index]],"MonthlyPlanPricesFormatted":[MonthlyPlanPricesFormatted[index]],"SlashPricesFormatted":[SlashPricesFormatted[index]],"AllFeatures":AllFeatures,"loading":_vm.getPlanLoading,"athleteId":_vm.TheAthleteId},on:{"getPlan":(plan) => _vm.selectPlan(plan)}})],1)}):[_c('SubscriptionsTablePlan',{attrs:{"plans":plans,"PlanPricesFormatted":PlanPricesFormatted,"MonthlyPlanPricesFormatted":MonthlyPlanPricesFormatted,"SlashPricesFormatted":SlashPricesFormatted,"AllFeatures":AllFeatures,"loading":_vm.getPlanLoading,"athleteId":_vm.TheAthleteId},on:{"getPlan":(plan) => _vm.selectPlan(plan)}})]],2):_vm._e()],1)]}}])},'SubscriptionPlanInfoProvider',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }