
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { mdiPencil, mdiClose } from '@mdi/js';
import { AthleteRoutingMixin, VuetifyMixin } from '../../mixins';
import { AthleteProfileModel } from '../../models/athlete/AthleteProfileModel';
import SportCard from '../ui/SportCard.vue';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { ContextMenuItem } from '@/../types/interfaces';
import { userStore } from '../../store';

@Component({
	components: { SportCard },
})
export default class ManageAthleteCard extends Mixins(VuetifyMixin, AthleteRoutingMixin) {

	@Prop({ type: Boolean, default: false }) myProfile: boolean;

	menuItems: ContextMenuItem[] = [
		{
			text: 'Edit',
			icon: mdiPencil,
			click: (): Promise<void> => this.editAthlete(),
		},
		{
			text: 'Remove',
			icon: mdiClose,
			click: (): Promise<void> => this.confirmDeleteAthlete(),
		}
	];

	async editAthlete(): Promise<void> {
		this.gotoEditProfile();
	}

	async deleteAthlete(athlete: AthleteProfileModel): Promise<void>{
		await userStore.removeAthleteProfileAccess({ athlete, userId: userStore.authId });
	}
	async confirmDeleteAthlete(): Promise<void>{
		console.log("Confirm Delete");
		this.confirmDelete = true;
	}
	get DeleteAthleteLoading(): boolean{
		return userStore.deleteAthleteProfileLoading;
	}

	confirmDelete: boolean = false;
	
	@Prop({ required: true }) athlete: AthleteProfileModel;

	get Over18(): boolean{
		return this.athlete.Age > 18;
	}
	get AgeLabel(): string{
		if(!this.Over18){
			return 'Child';
		}
		return 'Adult';
	}
	get AgeLabelColor(): string{
		if(this.AgeLabel === 'Child'){
			return this.getColor('baColorAccentGold');
		}
		return this.getColor('baColorGreen1');
	}

	get AvatarSize(): {
		size: number,
		borderSize: number,
		}{
		if(this.IsMobile){
			return {
				size: 75,
				borderSize: 3,
			};
		}
		return {
			size: 110,
			borderSize: 6,
		};
	}
	get ImpersonateAthleteProfileRoute(): string{
		return Routes.AthleteDashboard;
	}

	goToProfile(athlete: AthleteProfileModel): void{
		if(this.myProfile){
			this.$router.push({
				name: Routes.AthleteDashboard,
				params: {
					athleteId: athlete.id,
				}
			});
		}else{
			this.impersonateAthlete(athlete);
		}
	}

	impersonateAthlete(athlete: AthleteProfileModel): void{
		this.$router.push({
			name: this.ImpersonateAthleteProfileRoute,
			params:{
				profileShortId: athlete.shortId,
				athleteId: athlete.id,
			}
		});
	}

}
