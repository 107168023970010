
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { VuetifyMixin, MyAthleteMixin, BAIconsMixin } from '@/mixins';
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import { PlanFeature } from '@best-athletes/ba-types'
import { CustomerCountry, Currency } from '@/../types/enums';
import { userStore } from '@/store';
import { PLAN_FEATURES } from '@/../types/constants/plan-features';
import SubscriptionInfoProvider from "@/components/hoc/SubscriptionInfoProvider.vue";

@Component({
	components:{
		SubscriptionInfoProvider,
	}
})
export default class SubscriptionPlanCard extends Mixins(VuetifyMixin, MyAthleteMixin, BAIconsMixin){
	@Prop({ required: true }) plan: SubscriptionPlanModel;
	@Prop({ type: Boolean }) groupDisplay;
	
	get ShowSlashPrice(): boolean {
		return false;
	}

	get IsLegend(): boolean {
		return !this.plan;
	}
	get CustomerCountry(): CustomerCountry{
		return userStore.CurrentUserCountry;
	}
	get CountryCurrency(): Currency {
		if( this.CustomerCountry === CustomerCountry.CA ) {
			return Currency.CAD;
		}
		return Currency.USD;
	}

	get AllFeatures(): PlanFeature[] {
		return PLAN_FEATURES;
	}

	get PlanTitle(): string{
		if( this.IsLegend ) return 'Best Athletes';
		return this.plan.title;
	}
	get PlanDescription(): string {
		if( this.IsLegend ) return 'Be Your Best. Take control of your future.';
		return this.plan.description;
	}
	get PlanSubtitle(): string {
		if( this.IsLegend ) return '';
		return this.plan.subtitle;
	}
	get SlashPrice(): string {
		if( this.IsLegend ) return 'annual';
		return this.plan.getSlashPriceFormatted(this.CountryCurrency);
	}
	get PlanPrice(): string {
		if( this.IsLegend ) return '2024';
		if( this.IsFree ) return 'Free';
		else return this.plan.getPriceFormatted(this.CountryCurrency);
	}
	get PriceHasImg(): boolean {
		if( this.IsLegend ) return false;
		if( this.IsFree ) return false;
		return true;
	}
	get PlanDuration(): string {
		if( this.IsLegend ) return 'Promotional';
		if( this.IsFree ) return 'forever';
		else return 'annually';
	}
	get PlanButton(): string {
		if( this.IsLegend ) return '';
		return `Get ${this.PlanTitle}`;
	}
	get PlanColor(): string {
		if( this.IsLegend ) return 'primary';
		return this.plan.getColor();
	}
	get PlanBackgroundColor(): string {
		if( this.IsLegend ) return 'transparent';
		return this.PlanColor;
	}
	get PlanButtonColor(): string {
		if( this.IsLegend ) return this.PlanBackgroundColor;
		return this.plan.getColor('button');
	}
	get PlanTextColor(): string {
		if( this.IsLegend ) return 'transparent--text'
		return 'white--text';
	}
	get PlanSlashColor(): string {
		if( this.IsLegend ) return this.PlanTextColor;
		return 'grey--text text--lighten-1';
	}
	get PlanPriceColor(): string {
		if( this.IsLegend ) return this.PlanTextColor;
		if( this.plan.title.includes('Standard') ) return 'amber--text text--lighten-5';
		if( this.plan.title.includes('Showcase') ) return 'amber--text';
		if( this.plan.title.includes('Elite') ) return 'amber--text text--lighten-1';
		return this.PlanTextColor;
	}
	get PlanCheckColor(): string {
		if( this.IsLegend ) return 'black--text';
		if( this.plan.title.includes('Standard') ) return 'green';
		if( this.plan.title.includes('Showcase') ) return 'green';
		if( this.plan.title.includes('Elite') ) return 'green';
		return 'green'
	}
	get PlanBlankColor(): string {
		if( this.IsLegend ) return 'black--text';
		if( this.plan.title.includes('Standard') ) return 'red';
		if( this.plan.title.includes('Showcase') ) return 'red';
		if( this.plan.title.includes('Elite') ) return 'red';
		return 'red'
	}
	get PlanFeatures(): PlanFeature[]{
		if(!this.plan) return this.AllFeatures;
		return this.plan.featureList;
	}
	get IsFree(): boolean {
		if( this.IsLegend ) return true;
		return this.plan.free;
	}
	HasFeatureDescription(feature: PlanFeature): boolean {
		const description = this.FeatureDescription(feature);
		if( !description ) return false;

		return( description.length > 0 );
	}
	HasFeatureDescriptionArray(feature: PlanFeature): boolean {
		const description = this.FeatureDescription(feature);
		if( !description ) return false;

		return Array.isArray(description);
	}
	FeatureDescription(feature: PlanFeature): string | string[] {
		if( !this.HasFeature(feature) ) return undefined;
		const planFeature = this.PlanFeatures.filter((planFeature) => { if( planFeature.id == feature.id ) return planFeature }).pop();
		return planFeature.planDescription;
	}
	HasFeature(feature: PlanFeature): boolean {
		return this.PlanFeatures.filter((planFeature) => { if( planFeature.id == feature.id ) return planFeature }).length > 0;
	}

	get UseCardView(): boolean{
		return this.$vuetify.breakpoint.smAndDown
	}

	selectPlan(): void {
		this.$emit('select', this.plan)
	}
}
