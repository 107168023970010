
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { VuetifyMixin, MyAthleteMixin } from '@/mixins';
import SubscriptionPlanCard from '@/components/subscriptions/SubscriptionPlanCard.vue';
import { SubscriptionPlanModel } from '@/models/subscriptionPlan/SubscriptionPlanModel';
import { PlanFeature } from '@best-athletes/ba-types'

@Component({
	components: { SubscriptionPlanCard }
})
export default class SubscriptionsTablePlan extends Mixins(VuetifyMixin, MyAthleteMixin){
	@Prop({ required: true }) plans: SubscriptionPlanModel[];
	@Prop({ required: true }) PlanPricesFormatted: string[];
	@Prop({ required: true }) MonthlyPlanPricesFormatted: string[];
	@Prop({ required: true }) SlashPricesFormatted: string[];
	@Prop({ required: true }) AllFeatures: PlanFeature[];
	@Prop({ default: false, type: Boolean}) loading: boolean;

	get UseCardView(): boolean{
		return this.plans.length === 1;
	}
	Loading(plan: SubscriptionPlanModel): boolean{
		return this.loading && (this.currentPlan === plan)
	}
	currentPlan = null
	getPlan(plan: SubscriptionPlanModel): void{
		this.currentPlan = plan;
		this.$emit('getPlan', plan)
	}
}
